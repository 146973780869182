import { createJcApiAxiosInstance } from '@jumpcloud-ap/axios-setup';

export class ProvidersApi {
  constructor() {
    this.axiosInstance = createJcApiAxiosInstance();
  }

  async getProviderDetails(providerId) {
    const response = await this.axiosInstance.get(`/api/v2/providers/${providerId}`);
    return response.data;
  }
}

export default new ProvidersApi();
