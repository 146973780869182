import { ViewportBannerConsts } from '@/components/ViewportBanner/constants/ViewportBannerConsts';
import { accountsReceivable, accountsReceivableOld } from '@/components/ViewportBanner/constants/AccountConstants';
import PastDueAccountFirstWarningBannerSlot from '@/components/ViewportBanner/PastDueAccountFirstWarningBannerSlot.vue';

const pastDueAccountFirstWarningBannerConfig = user => ({
  name: ViewportBannerConsts.names.pastDueAccountFirstWarningBanner,
  bannerText: '',
  childComponent: PastDueAccountFirstWarningBannerSlot,
  bannerType: ViewportBannerConsts.types.error,
  bannerVisibility: () => !user.isAnMsp
    && (
      // TODO: PU-841 cleanup after migration to new constants is done
      user.accountsReceivable === accountsReceivableOld.WARNING
      || user.accountsReceivable === accountsReceivable.FIRST_WARNING
      || user.accountsReceivable === accountsReceivable.AWS_FIRST_WARNING
    ),
  canCloseBanner: false,
});

export default pastDueAccountFirstWarningBannerConfig;
