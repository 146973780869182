import { UpgradeLoginNudgeController } from '@jumpcloud-ap/feature-trials';

const availableNudges = {
  upgradeAtLogin: 'upgradeAtLogin',
};

/**
 * A "nudge" is a component displayed in screen
 * after meeting specific conditions
 * to encourage the user to do something
 * Example:
 * - user has seen paywall and didn't purchase
 * - display paywall abandoned nudge to encourage user to see paywall again and purchase
 *
 * @param {String} nudgeName
 */
const setup = (nudgeName) => {
  if (!Object.values(availableNudges).includes(nudgeName)) {
    throw new Error(`invalid nudge name: "${nudgeName}"`);
  }

  switch (nudgeName) {
    // add all nudge cases here
    case availableNudges.upgradeAtLogin:
      UpgradeLoginNudgeController.setUpgradeLoginNudge();
      break;

    default:
      // do nothing
      break;
  }
};

export default {
  availableNudges,
  setup,
};
