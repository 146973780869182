export const ViewportBannerConsts = {
  names: {
    pastDueAccountFirstWarningBanner: 'pastDueAccountFirstWarningBanner',
    pastDueAccountSecondWarningBanner: 'pastDueAccountSecondWarningBanner',
    closedCustomerNeedsCCBanner: 'closedCustomerNeedsCCBanner',
  },
  types: {
    danger: 'error',
    error: 'error',
    informational: 'informational',
    secondary: 'informational',
    warning: 'warning',
  },
};
