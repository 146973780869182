<template>
  <ModalConfirmation
    data-test-id="InactivityModal"
    hasBlur
    :showModal="isModalVisible"
    title="Session Expiration"
  >
    <template v-slot:body>
      <ParagraphBody>
        Due to inactivity, your session will expire in
      </ParagraphBody>
      <HeadingMedium>
        <span>{{ secondsLeft }}</span>
        <span> seconds</span>
      </HeadingMedium>
    </template>
    <template v-slot:footer>
      <ButtonGroupSave
        :isFullWidth="false"
        :primaryButtonProps="primaryButtonProps"
        :secondaryButtonProps="secondaryButtonProps"
        @primaryAction="handleClose"
        @secondaryAction="endSession"
      />
    </template>
  </ModalConfirmation>
</template>

<script>
import {
  ButtonGroupSave,
  HeadingMedium,
  ModalConfirmation,
  ParagraphBody,
  Types,
} from '@jumpcloud/ui-components';
import InactivityModalController from './InactivityModalController';

const {
  createListeners,
  deleteListeners,
  getSecondsLeftUntilSessionExpiration,
  updateLastActiveTime,
} = InactivityModalController;

const { PrimaryButtonProps, SecondaryButtonProps } = Types;

export default {
  name: 'InactivityModal',

  components: {
    ButtonGroupSave,
    HeadingMedium,
    ModalConfirmation,
    ParagraphBody,
  },

  data: () => ({
    intervalID: null,
    isModalVisible: false,
    secondsLeft: 60,
  }),

  computed: {
    primaryButtonProps() {
      return PrimaryButtonProps({ text: 'Continue Session' });
    },

    secondaryButtonProps() {
      return SecondaryButtonProps({ text: 'Log Out' });
    },
  },

  created() {
    this.initializeInactivityTracker();
  },

  methods: {
    endSession() {
      this.resetSession();
      window.location.assign('/#/logout');
    },

    handleClose() {
      updateLastActiveTime();
      this.resetSession();
    },

    initializeInactivityTracker() {
      // utilize arrow funciton to implicitly bind this
      const timerCallback = () => {
        if (this.isModalVisible) return;

        this.secondsLeft = getSecondsLeftUntilSessionExpiration();

        // because chrome throttles setInterval to once per minute when the user is not on the tab
        // we may need to log out without even showing the modal
        if (this.secondsLeft < 0) {
          this.endSession();
        } else if (this.secondsLeft <= 60) {
          this.showSessionExpirationModal();
        }
      };

      createListeners();
      setInterval(timerCallback, 10000);
    },

    resetSession() {
      createListeners();
      this.isModalVisible = false;
      clearInterval(this.intervalID);
      this.intervalID = null;
    },

    showSessionExpirationModal() {
      this.isModalVisible = true;
      // do not update activity time when modal is visible
      deleteListeners();

      // check to see if there is already an active countdown
      if (!this.intervalID) {
        // start the countdown
        this.intervalID = setInterval(() => {
          // recalculate seconds left each interval to check other tab ativities
          this.secondsLeft = getSecondsLeftUntilSessionExpiration();

          if (this.secondsLeft < 1) {
            this.endSession();
          } else if (this.secondsLeft > 60) {
            // will have more than 60 seconds left if activity in another tab
            this.resetSession();
          }
        }, 1000);
      }
    },
  },
};
</script>
