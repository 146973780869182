import OrganizationsApi from '@/api/OrganizationsApi';
import Vue from 'vue';

const initialState = () => ({
  entities: {
    items: {
      byId: {},
    },
  },
  features: {
    items: {
      ids: [],
    },
  },
});

export const createOrganizationsCollection = (api = OrganizationsApi) => ({
  namespaced: true,

  state: initialState(),

  actions: {
    fetchCollection(context = {}) {
      return api.fetch(0, 50)
        .then(items => {
          const payload = {
            results: items.results,
            totalCount: items.totalCount,
            nextPageToken: items.nextPageToken,
            skipToken: items.skipToken,
          };

          if (context.state.entities.items.totalCountOfAllItems === null) {
            payload.totalCountOfAllItems = items.totalCount;
          }

          context.commit('SET_COLLECTION', payload);

          return items.results.map(result => result._id);
        })
        .then(ids => {
          if (!context.state.features.boundItemsUrl
            || context.state.features.showBoundItems
            || context.state.features.hasBoundItemsTotalCountBeenSet) {
            return ids;
          }

          return null;
        });
    },

    refreshCollection(context = {}) {
      const { entities } = context.state;

      return api.fetch(0, 50)
        .then(({ results, totalCount }) => {
          const payload = {
            results,
            totalCount,
          };

          if (entities.items.totalCountOfAllItems !== totalCount) {
            payload.totalCountOfAllItems = totalCount;
          }

          context.commit('REFRESH_COLLECTION', payload);
        });
    },
  },

  mutations: {
    SET_COLLECTION(state, items) {
      if (!items || !items.results) return;

      items.results.forEach(result => {
        const id = result._id;
        Vue.set(state.entities.items.byId, id, {
          ...state.entities.items.byId[id],
          ...result,
        });
      });

      const newIds = items.results.map(result => result._id);
      // eslint-disable-next-line no-param-reassign
      state.features.items.ids = [...new Set([...state.features.items.ids, ...newIds])];

      if (typeof items.totalCount === 'number') {
        // eslint-disable-next-line no-param-reassign
        state.entities.items.totalCount = items.totalCount;
      }
    },

    REFRESH_COLLECTION(state, refreshedItems) {
      if (refreshedItems.results.length > 0) {
        const refreshedItemsById = refreshedItems.results.reduce((acc, item) => {
          acc[item._id] = item;
          return acc;
        }, {});

        Object.keys(state.entities.items.byId).forEach(id => {
          if (!Object.prototype.hasOwnProperty.call(refreshedItemsById, id)) {
            Vue.delete(state.entities.items.byId, id);
          }
        });

        Object.keys(refreshedItemsById).forEach(id => {
          Vue.set(state.entities.items.byId, id, refreshedItemsById[id]);
        });

        // eslint-disable-next-line no-param-reassign
        state.features.items.ids = refreshedItems.results.map(item => item._id);
      }

      // eslint-disable-next-line no-param-reassign
      state.entities.items.totalCountOfAllItems = refreshedItems.totalCount;
    },
  },

  getters: {
    getCollection(state = { features: { items: { ids: [] } }, entities: { items: { byId: {} } } }) {
      return state.features.items.ids.map(id => state.entities.items.byId[id]);
    },
  },
});

export default createOrganizationsCollection();
