<template>
  <ModalStructure
    :data-test-id="$testId('modal')"
    footerAlignment="right"
    :showCloseButton="true"
    :showModal="showModal"
    @close="handleClose"
  >
    <template v-slot:header>
      <HeadingMedium
        :data-test-id="$testId('headerText')"
      >
        You don't have permission to see payment information
      </HeadingMedium>
    </template>
    <template v-slot:body>
      <div
        v-show="!showLoadingOverlay"
        :data-test-id="$testId('modalBodyMessage')"
      >
        <ParagraphBody v-if="billingAdmin.email">
          Contact <strong>{{ billingAdmin.email }}</strong> to get permission to view
          and edit your organization payment information.
        </ParagraphBody>
        <ParagraphBody v-else>
          Please reach out to your managed service provider for billing information.
        </ParagraphBody>
      </div>
      <LoadingOverlay
        isLight
        :visible="showLoadingOverlay"
      />
    </template>
    <template v-slot:footer>
      <Button
        aria-label="pay now button"
        :data-test-id="$testId('okButton')"
        text="OK"
        type="primary"
        @click="handleClose"
      />
    </template>
  </ModalStructure>
</template>

<script>
import { mapActions } from 'vuex';

import {
  Button,
  HeadingMedium,
  LoadingOverlay,
  ModalStructure,
  ParagraphBody,
} from '@jumpcloud/ui-components';
import { UserRoleConsts } from '@jumpcloud-ap/core-user-info';
import { notificationModuleName as notificationModule } from '@jumpcloud-ap/notifications';
import UsersApi from '@/api/UsersApi';

export default {
  name: 'NoBillingPermissionsModal',

  components: {
    Button,
    HeadingMedium,
    LoadingOverlay,
    ModalStructure,
    ParagraphBody,
  },

  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      showLoadingOverlay: false,
      users: [],
    };
  },

  computed: {
    /**
     * Admin !== Admin with Billing
     * See https://support.jumpcloud.com/support/s/article/JumpCloud-Roles
     */
    billingAdmin() {
      const admin = this.users.find(
        user => user.roleName === UserRoleConsts.administratorWithBilling,
      );

      return admin || {};
    },
  },

  mounted() {
    this.handleMounted();
  },

  methods: {
    ...mapActions(notificationModule, [
      'triggerErrorNotification',
    ]),

    handleClose() {
      this.$emit('update:showModal', false);
    },

    async handleMounted() {
      this.showLoadingOverlay = true;

      try {
        const users = await UsersApi.fetchUsers();
        this.users = users.results;
      } catch {
        this.triggerErrorNotification({ message: 'Unable to fetch collection' });
      } finally {
        this.showLoadingOverlay = false;
      }
    },
  },
};
</script>
