import { ViewportBannerConsts } from '@/components/ViewportBanner/constants/ViewportBannerConsts';
import { accountsReceivable, accountsReceivableOld } from '@/components/ViewportBanner/constants/AccountConstants';
import PastDueAccountSecondWarningBannerSlot from '@/components/ViewportBanner/PastDueAccountSecondWarningBannerSlot.vue';

const pastDueAccountSecondWarningBannerConfig = user => ({
  name: ViewportBannerConsts.names.pastDueAccountSecondWarningBanner,
  bannerText: '',
  childComponent: PastDueAccountSecondWarningBannerSlot,
  bannerType: ViewportBannerConsts.types.error,
  bannerVisibility: () => !user.isAnMsp
    && (
      // TODO: PU-841 cleanup after migration to new constants is done
      user.accountsReceivable === accountsReceivableOld.BANNER
    || user.accountsReceivable === accountsReceivable.SECOND_WARNING
    || user.accountsReceivable === accountsReceivable.AWS_SECOND_WARNING
    ),
  canCloseBanner: false,
});

export default pastDueAccountSecondWarningBannerConfig;
