<template>
  <div>
    <Icon
      :class="$style.warningIcon"
      icon="error"
      inline
    />
    <slot name="intro" />
    <span v-if="!isVarOrDistributor">
      <ButtonLink
        :data-test-id="$testId('payNowLink')"
        text="Pay now"
        @click="handlePayNowClick"
      />
      via credit card or email
      <ButtonLink
        :class="$style.billingMail"
        :data-test-id="$testId('mailToBillingLink')"
        :text="$options.billingMail"
        @click="openMailToBillingLink"
      />
      to use another method of payment.
      <ButtonLink
        :data-test-id="$testId('learnMoreLink')"
        :text="$options.learnMoreLinkCopy"
        @click="openLearnMoreLink"
      />.
      <PayNowModal
        :showModal.sync="showPayNowModal"
      />
      <NoBillingPermissionsModal
        :showModal.sync="showNoBillingPermissionsModal"
      />
    </span>
  </div>
</template>

<script>
import {
  ButtonLink,
  Icon,
} from '@jumpcloud/ui-components';
import { PayNowModal } from '@jumpcloud-ap/accounts-receivable';
import { UiEvents } from '@jumpcloud/ui-events-vue';
import { mapGetters } from 'vuex';
import NoBillingPermissionsModal from '@/components/ViewportBanner/NoBillingPermissionsModal.vue';
import PastDueEmailbillingMixin from '@/components/ViewportBanner/PastDueEmailbillingMixin';

export default {
  name: 'PastDueAccount',

  components: {
    ButtonLink,
    Icon,
    NoBillingPermissionsModal,
    PayNowModal,
  },

  mixins: [PastDueEmailbillingMixin],

  props: {
    eventPayload: {
      type: Object,
      required: true,
    },
    isVarOrDistributor: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showPayNowModal: false,
      showNoBillingPermissionsModal: false,
    };
  },

  computed: {
    ...mapGetters('CoreUserInfoModule', [
      'hasBilling',
      'orgId',
    ]),
  },

  created() {
    this.triggerBannerDisplayedEvent();
  },

  methods: {

    handlePayNowClick() {
      if (this.hasBilling) {
        this.showPayNowModal = true;
      } else {
        this.showNoBillingPermissionsModal = true;
      }

      UiEvents.triggerButtonClicked({
        description: 'Clicks Pay Now',
        page: this.$page,
        region: 'header',
        section: 'Pay Now Banner',
        text: 'Pay Now',
      });
    },

    openLearnMoreLink() {
      window.open(this.$options.billingLink, '_blank');
      this.triggerLinkClicked(this.$options.learnMoreLinkCopy, this.$options.billingLink);
    },

    openMailToBillingLink() {
      window.open(this.mailToBillingLink, '_blank');
      this.triggerLinkClicked(this.$options.billingMail, this.mailToBillingLink);
    },

    triggerBannerDisplayedEvent() {
      UiEvents.triggerNudgeViewed({
        page: this.$page,
        ...this.eventPayload,
      });
    },

    triggerLinkClicked(text, toUrl) {
      UiEvents.triggerLinkClicked({
        page: this.$page,
        text,
        toUrl,
        ...this.eventPayload,
      });
    },
  },
};
</script>

<style module scoped>
.billingMail.billingMail {
  text-transform: lowercase;
}

.warningIcon {
  color: var(--jc-color-error-on-surface);
}
</style>
