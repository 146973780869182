import { DateUtil } from '@jumpcloud-ap/date-util';

const getValue = (key) => {
  const localStorageValue = window.localStorage.getItem(key);

  let value = localStorageValue;

  if (localStorageValue === 'true') value = true;
  if (localStorageValue === 'false') value = false;

  return value;
};

const NewlySelfServedBannerConfig = user => ({
  name: 'newlySelfServedBanner',
  bannerText: "New Customer Survey – $50 gift card prize (one winner every month)! This survey helps us ensure we continue to improve our experience for users like you, we would really appreciate your insights! And, in the month you take the survey you'll have the opportunity to win a $50 gift card!",
  learnMoreLink: 'https://www.surveymonkey.com/r/XPFNNC6',
  learnMoreText: 'Take survey.',
  localStorageKey: 'jcBannerDismissedNewlySelfServed',
  // compare self served timestamp to current date to see if a month has past
  bannerVisibility: () => user
    && !!getValue('jcCustomerSelfServedTimestamp')
    && !DateUtil.moreThanDaysBeforeToday(
      getValue('jcCustomerSelfServedTimestamp'), 30,
    ),
});

export default NewlySelfServedBannerConfig;
