import { type AxiosInstance, createJcApiAxiosInstance } from '@jumpcloud-ap/axios-setup';

interface Status {
  description: string;
  indicator: 'none' | 'minor' | 'major' | 'critical' | 'maintenance';
}

interface JCStatusResponse {
  status: Status;
}

export class StatusApi {
  private axiosInstance: AxiosInstance;
  private statusUrl: string;

  constructor() {
    this.axiosInstance = createJcApiAxiosInstance();
    this.statusUrl = '';
  }

  async loadBaseUrl(): Promise<void> {
    // Pulling from local dev for ui-settings
    const { data } = await this.axiosInstance.get<{ jcStatusUrl: string }>('/ui-settings.json', { baseURL: '' });
    this.statusUrl = data.jcStatusUrl;
  }

  /**
   * @returns The status response from JC API
   * @throws Will throw an error if the url evaluates false.
   */
  async getStatus(): Promise<JCStatusResponse> {
    if (!this.statusUrl) {
      await this.loadBaseUrl();
    }

    if (!this.statusUrl) {
      throw new Error('ui-settings.json did not define jcStatusUrl');
    }
    const response = await this.axiosInstance.get<JCStatusResponse>(`${this.statusUrl}/api/v2/status.json`);
    return response.data;
  }
}

export default new StatusApi();
