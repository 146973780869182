import { ViewportBannerConsts } from '@/components/ViewportBanner/constants/ViewportBannerConsts';

const MspTrialBanner = (user, provider) => ({
  name: 'MspTrialBanner',
  bannerText: 'Enjoying your JumpCloud trial? To learn more about JumpCloud\'s partner benefits and get connected with our team, ',
  bannerType: ViewportBannerConsts.types.warning,
  learnMoreLink: 'https://jumpcloud.com/partners/get-in-touch',
  learnMoreText: 'click here!',
  bannerVisibility: () => !!user.provider
      && provider.disallowOrgCreation,
  canCloseBanner: true,
});

export default MspTrialBanner;
