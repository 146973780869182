// TODO: PU-841 cleanup after migration to new constants is done
export const accountsReceivableOld = {
  BANNER: 'Banner',
  FROZEN: 'Frozen',
  NONE: 'None',
  WARNING: 'Warning',
};
export const accountsReceivable = {
  FIRST_WARNING: 'First Warning', // Previously warning
  SECOND_WARNING: 'Second Warning', // Previously Banner
  ADMIN_SUSPENSION: 'Admin Suspension', // Previously Frozen
  AWS_FIRST_WARNING: 'AWS First Warning',
  AWS_SECOND_WARNING: 'AWS Second Warning',
  AWS_ADMIN_SUSPENSION: 'AWS Admin Suspension',
  USER_SUSPENSION: 'User Suspension', // Previously Frozen
  USER_DELETION: 'User Deletion', // Previously Frozen
  NONE: 'None',
};
