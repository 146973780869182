export default {
  set(callback: () => void, interval: number): number {
    if (typeof callback !== 'function') {
      throw new Error('Callback must be a function');
    }
    const intervalId = window.setInterval(callback, interval);

    return intervalId;
  },

  clear(intervalId: number): void {
    window.clearInterval(intervalId);
  },
};
