import { throttle } from 'lodash';
import LocalStorageService from '@/utils/LocalStorageService';

const idleMinutesAllowed = 60;
const idleSecondsAllowed = idleMinutesAllowed * 60;

const lastActiveTimeKey = 'lastActiveTime';

const updateLastActiveTime = () => {
  const lastActiveTime = new Date().getTime();
  LocalStorageService.setItem(lastActiveTimeKey, lastActiveTime);
};

// throttle to update local storage once every 10 seconds
// https://css-tricks.com/debouncing-throttling-explained-examples/
const localStorageUpdateInterval = 10000;
const throttledUpdateLastActiveTime = throttle(updateLastActiveTime, localStorageUpdateInterval);

const createListeners = () => {
  window.addEventListener('mousemove', throttledUpdateLastActiveTime);
  window.addEventListener('touchstart', throttledUpdateLastActiveTime);
  window.addEventListener('keydown', throttledUpdateLastActiveTime);
  document.addEventListener('scroll', throttledUpdateLastActiveTime, true);
};

const deleteListeners = () => {
  window.removeEventListener('mousemove', throttledUpdateLastActiveTime);
  window.removeEventListener('touchstart', throttledUpdateLastActiveTime);
  window.removeEventListener('keydown', throttledUpdateLastActiveTime);
  document.removeEventListener('scroll', throttledUpdateLastActiveTime, true);
};

const getLastActiveTime = () => LocalStorageService.getItem(lastActiveTimeKey);

const getSecondsLeftUntilSessionExpiration = () => {
  const lastActiveTime = getLastActiveTime();
  return idleSecondsAllowed - Math.floor(
    (new Date().getTime() - lastActiveTime) / 1000,
  );
};

export default {
  createListeners,
  deleteListeners,
  getLastActiveTime,
  getSecondsLeftUntilSessionExpiration,
  updateLastActiveTime,
};
