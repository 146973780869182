import { isFunction } from 'lodash';

export default {
  getMtpOrgSwitchingRedirectHash(currentRoute, location) {
    // The default hash where user will be redirected to after switching org (if the process
    // below doesn't produce a different result)
    let hash = '#/dashboard';

    const { matched = [] } = currentRoute;
    const {
      path,
      meta: {
        mtpOrgSwitchingRedirectPath,
      } = {},
    } = matched[0] || {};
    const pathParam = (path || '').split('/').find(segment => segment.startsWith(':'));

    // When mtpOrgSwitchingRedirectPath is specified, we should redirect user there
    // after switching org
    if (isFunction(mtpOrgSwitchingRedirectPath)) {
      hash = `#${mtpOrgSwitchingRedirectPath(currentRoute)}`;
    } else if (mtpOrgSwitchingRedirectPath != null && typeof mtpOrgSwitchingRedirectPath.valueOf() === 'string') {
      hash = `#${mtpOrgSwitchingRedirectPath}`;
    } else if (pathParam) {
      // If user was on a route that has a parameter (e.g. :id, :os?) before switching orgs
      // (e.g. /users/:id/details or /configurations/configure/:os/:templateId
      // or /security/authnpolicies/:id) they should be redirected to upper level
      // page after switching since the previous resource might not exist in the
      // new org.
      const pathBeforeParam = path.split(`/${pathParam}`)[0];
      hash = `#${pathBeforeParam}`;
    } else {
      // Otherwise, redirect to the path before the query string parameters (?)
      // e.g. /devices/new, groups/configuration
      [hash] = location.hash.split('?');
    }

    return hash;
  },
};
