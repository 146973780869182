import { ViewportBannerConsts } from '@/components/ViewportBanner/constants/ViewportBannerConsts';
import AdvancedCertificationBannerSlot from '@/components/ViewportBanner/AdvancedCertificationBannerSlot.vue';

const advancedCertificationBannerDismissed = '2023-09-27-jcAdvancedCertificationBannerDismissed';

const AdvancedCertificationBanner = () => ({
  name: 'AdvancedCertificationBanner',
  bannerText: '',
  bannerType: ViewportBannerConsts.types.warning,
  childComponent: AdvancedCertificationBannerSlot,
  localStorageKey: advancedCertificationBannerDismissed,
  startDate: '2023-10-02',
  endDate: '2023-10-13',
  bannerVisibility: () => true,
});

export default AdvancedCertificationBanner;
