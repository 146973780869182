export default {
  clearIsAnMsp() {
    window.sessionStorage.removeItem('jc-isMsp');
  },

  clearIsAnMspOrChild() {
    window.sessionStorage.removeItem('isMspOrChild');
  },

  clearOrgId() {
    window.sessionStorage.removeItem('jc-orgId');
  },

  clearProviderId() {
    window.sessionStorage.removeItem('jc-providerId');
  },

  clearStorage() {
    this.clearIsAnMsp();
    this.clearIsAnMspOrChild();
    this.clearOrgId();
    this.clearProviderId();
  },
};
