<template>
  <Banner
    :bannerText="bannerText"
    :bannerType="bannerType"
    :canCloseBanner="false"
    :learnMoreLink="$options.kbUrl"
    :learnMoreText="'View status page'"
    :showBanner="showBanner"
  >
    <template v-slot:content>
      <div
        :class="$style.bannerContent"
      >
        <Icon
          :class="bannerType === 'error' ? $style.error : $style.warning"
          icon="error"
          :scale="1.25"
        />
        <span>{{ bannerText }} </span>
        <a
          :class="$style.link"
          :href="$options.kbUrl"
          rel="noopener noreferrer"
          target="_blank"
        >View status page
          <Icon
            :class="$style.linkIcon"
            icon="externalLink"
            :scale="0.75"
          />
        </a>
      </div>
    </template>
  </Banner>
</template>

<script>
import { Banner, Icon } from '@jumpcloud/ui-components';
import StatusApi from '@/api/StatusApi';
import getEnvironment from '@/utils/GetEnvironment';

export default {
  name: 'StatusBanner',

  StatusApi,

  components: {
    Banner,
    Icon,
  },

  kbUrl: 'https://status.jumpcloud.com/',

  data() {
    return {
      bannerText: 'JumpCloud is experiencing issues that may impact console functionality.',
      bannerType: 'warning',
      showBanner: false,
      statusUpdateInterval: 0,
    };
  },

  created() {
    this.fetchStatus();
    this.statusUpdateInterval = setInterval(() => this.fetchStatus(), 10 * 60 * 1000); // 10min
  },

  destroyed() {
    if (this.statusUpdateInterval) {
      clearInterval(this.statusUpdateInterval);
    }
  },

  methods: {
    async fetchStatus() {
      try {
        const result = await this.$options.StatusApi.getStatus();
        this.showBanner = true;
        switch (result.status.indicator) {
          case 'maintenance':
          case 'minor':
            this.bannerType = 'warning';
            break;
          case 'major':
          case 'critical':
            this.bannerType = 'error';
            break;
          default:
            this.showBanner = false;
        }
      } catch (e) {
        this.showBanner = false;
        if (e.message === 'ui-settings.json did not define jcStatusUrl'
            && getEnvironment() !== 'production') {
          // skip 404 errors in non-production
        } else {
          throw new Error(e);
        }
      }
    },
  },

};
</script>

<style module scoped>
.link {
  border-bottom: var(--jc-border-width) solid;
  color: currentColor;
  line-height: 1.5;
  margin-left: 0.25em;
}

.link:hover {
  color: var(--jc-color-informational-strong);
}

.linkIcon {
  margin-left: 5px;
}

.warning {
  color: var(--jcWarning900);
}

.error {
  color: var(--jcError900);
}

.bannerContent > * {
  vertical-align: middle;
}

.bannerContent > *:first-child {
  padding-right: 10px;
}

</style>
