<template>
  <div>
    <Icon
      icon="info"
      inline
    />
    We don't have payment information on file for this org. Please <ButtonLink
      :class="$style.lowercase"
      text="add payment info"
      @click="handleClick"
    /> before the end of your first billing period. Email <Link
      href="mailto:billing@jumpcloud.com"
      target="_blank"
      text="billing@jumpcloud.com"
    /> if you have any questions.
  </div>
</template>

<script>
import {
  ButtonLink,
  Icon,
  Link,
} from '@jumpcloud/ui-components';
import { PaymentHelper } from '@jumpcloud-ap/payments';

export default {
  name: 'ClosedCustomerNeedsCCBanner',

  components: {
    ButtonLink,
    Icon,
    Link,
  },

  methods: {
    handleClick() {
      PaymentHelper.openPaymentInformationForClosedCustomer();
    },
  },
};
</script>

<style scoped module>
.lowercase {
  text-transform: lowercase;
}
</style>
