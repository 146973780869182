<template>
  <div>
    <PastDueAccount
      :data-test-id="$testId('pastDueAccount')"
      :eventPayload="eventPayload"
      :isVarOrDistributor="isVarOrDistributor"
    >
      <template v-slot:intro>
        <span :data-test-id="$testId('intro')">
          {{ bannerText }}
        </span>
      </template>
    </PastDueAccount>
  </div>
</template>

<script>
import { accountsReceivable } from '@/components/ViewportBanner/constants/AccountConstants';
import {
  mapGetters,
} from 'vuex';
import OrganizationsApi from '@/api/OrganizationsApi';
import PastDueAccount from '@/components/ViewportBanner/PastDueAccount.vue';

export default {
  name: 'PastDueAccountFirstWarningBannerSlot',

  components: {
    PastDueAccount,
  },

  data() {
    return {
      accountsReceivable: accountsReceivable.NONE,
    };
  },

  computed: {
    ...mapGetters('EntitlementModule', ['isVarOrDistributor']),

    ...mapGetters('CoreUserInfoModule', ['orgId']),

    bannerText() {
      const isAWSCustomer = this.accountsReceivable === accountsReceivable.AWS_FIRST_WARNING;

      let text = 'You have an outstanding balance on your account.';

      if (isAWSCustomer) {
        text = `The account has a past due balance. Please
          contact AWS to make a payment or resolve the matter
          if a payment has been made.`;
      } else if (this.isVarOrDistributor) {
        text = `The account has a past due balance. Please
          contact your reseller to make a payment or resolve
          the matter if the payment has been made.`;
      }

      return text;
    },

    eventPayload() {
      return {
        description: 'Accounts Receivable first warning in Admin Portal',
        displayLogic: '"accountsReceivable" for org is "First Warning"',
        section: 'Banners',
      };
    },
  },

  async created() {
    const orgDetails = await OrganizationsApi.getOrganizationDetails(this.orgId);
    this.accountsReceivable = orgDetails.accountsReceivable;
  },

};
</script>
