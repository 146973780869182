import { CollectionApi } from '@jumpcloud-ap/collection-api';

export class OrganizationsApi extends CollectionApi {
  constructor() {
    super('/api/organizations', {
      limit: 50,
      skip: 0,
      sortIgnoreCase: 'settings.name',
    });
  }

  async getOrganizationDetails(id) {
    const fields = [
      'accountsReceivable',
      'created',
    ].join(' ');

    const { data } = await this.axiosInstance.get(`${this.baseUrl}/${id}?fields=${fields}`);

    return data || {};
  }
}

export default new OrganizationsApi();
