type Environment =
  | 'local'
  | 'staging'
  | 'production';

export default function getEnvironment(hostname = window.location.hostname): Environment {
  if (hostname === 'localhost' || hostname === 'console.jumpcloud.local') {
    return 'local';
  }

  if (hostname === 'console.stg01.jumpcloud.com') {
    return 'staging';
  }

  return 'production';
}
