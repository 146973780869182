import { ViewportBannerConsts } from '@/components/ViewportBanner/constants/ViewportBannerConsts';
import ClosedCustomerNeedsCCBanner from '@/components/ViewportBanner/ClosedCustomerNeedsCCBanner.vue';

const loginClosedCustomerCCSeen = '2024-02-12-loginClosedCustomerCCSeen';

const closedCustomerCCBannerConfig = user => ({
  name: ViewportBannerConsts.names.closedCustomerNeedsCCBanner,
  bannerText: '',
  childComponent: ClosedCustomerNeedsCCBanner,
  bannerType: ViewportBannerConsts.types.informational,
  bannerVisibility: () => {
    const closedCustomerModalSeen = !!window.localStorage.getItem(loginClosedCustomerCCSeen);
    return !user.isCustomer && closedCustomerModalSeen;
  },
  canCloseBanner: false,
});

export default closedCustomerCCBannerConfig;
