<template>
  <div
    :class="$style.nudgeWrapper"
  >
    <component
      :is="nudgeComponent.component"
      v-for="nudgeComponent in nudgeComponents"
      :key="nudgeComponent.key"
      v-bind="nudgeComponent.props"
      :data-test-id="$testId('nudgeComponent')"
    />
  </div>
</template>

<script>
import { UpgradeLoginNudge, UpgradeLoginNudgeController } from '@jumpcloud-ap/feature-trials';
import NudgeHelper from './NudgeHelper';

export default {
  name: 'ViewportNudge',

  UpgradeLoginNudgeController,

  components: {
    UpgradeLoginNudge,
  },

  data() {
    return {
      nudgeComponents: [],
    };
  },

  computed: {
    availableNudges() {
      return this.$options.UpgradeLoginNudgeController.getAvailableUpgradeLogins(this.$store);
    },

    currentProps() {
      return (this.currentNudge === UpgradeLoginNudge) ? { featureName: this.featureTrials } : {};
    },
  },

  created() {
    NudgeHelper.setup(NudgeHelper.availableNudges.upgradeAtLogin);
  },

  mounted() {
    this.setCurrentNudge();
  },

  methods: {
    setCurrentNudge() {
      if (this.availableNudges.length > 0) {
        (this.availableNudges).forEach(nudgeInfo => {
          this.nudgeComponents.push({
            component: UpgradeLoginNudge,
            props: nudgeInfo,
          });
        });
      }
    },
  },
};
</script>

<style module scoped>
.nudgeWrapper {
  bottom: var(--jc-spacer-medium);
  position: fixed;
  right: var(--jc-spacer-medium);
  width: 30rem;
  z-index: 600;
}

</style>
