import Vue from 'vue';
import Vuex from 'vuex';

import { FeatureFlagsModule } from '../feature-flags';
import { createCoreUserInfoModule } from '@jumpcloud-ap/core-user-info';
import { createRouteInfoModule } from '@jumpcloud-ap/route-info';
import { setupFeatureTrialModule } from '@jumpcloud-ap/feature-trials';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    FeatureFlagsModule,
    CoreUserInfoModule: createCoreUserInfoModule(),
    RouteInfoModule: createRouteInfoModule(),
  },
});

setupFeatureTrialModule(store);

export {
  store,
};
