import { mapGetters } from 'vuex';

const CreateMailToLink = function (email, params = {}) {
  const base = `mailto:${email}`;
  const arrayParams = Object.entries(params);

  if (!arrayParams.length) return base;

  let paramString = '?';

  Object.entries(params).forEach(([key, value]) => {
    paramString += `${key}=${value}&`;
  });

  return `${base}${paramString}`;
};

const mailToLink = ({ orgId, orgName, userName }) => CreateMailToLink('billing@jumpcloud.com', {
  subject: 'My JumpCloud account is in default',
  body: encodeURIComponent(
    `I'd like to chat with someone about my account that is currently in default.\n\nOrganization ID: ${orgId || ''}\nName: ${orgName || ''}\nPhone:\nMethod of payment: {fill in your preferred payment method}\n\n—Note: Credit cards that are updated successfully will be charged the outstanding balance within one (1) business day. When paying with a method other than credit card, access to your Admin Portal administration will be restored within 2 business days of successful remediation.\n\nThank you, \n${userName || 'IT Admin'}`,
  ),
});

export default {
  computed: {
    ...mapGetters('CoreUserInfoModule', [
      'firstName',
      'lastName',
      'name',
      'orgId',
    ]),
    mailToBillingLink() {
      const userName = `${this.firstName ?? ''} ${this.lastName ?? ''}`.trim();
      const orgData = { orgId: this.orgId, orgName: this.name, userName };

      return mailToLink(orgData);
    },
  },

  billingLink: 'https://jumpcloud.com/support/faq-billing-charges-and-invoices',
  billingMail: 'billing@jumpcloud.com',
  learnMoreLinkCopy: 'Learn more',
};
