<template>
  <NotificationController
    :notificationConfig="$options.NotificationConfig"
  >
    <template
      v-slot="slotProps"
    >
      <NotificationContainer
        id="notificationsContainer"
        :class="$style.notificationContainer"
        :data-test-id="$testId('notifications')"
        :notifications="slotProps.notifications"
        @removeNotification="slotProps.removeNotification"
      />
    </template>
  </NotificationController>
</template>

<script>
import { NotificationContainer, Types } from '@jumpcloud/ui-components';
import { NotificationController, notificationModuleName as uiNotificationModuleName } from '@jumpcloud/ui-notifications';
import { mapActions } from 'vuex';
import { notificationEventBus } from '@jumpcloud-ap/notifications';

const { NotificationConfig } = Types;

export default {
  name: 'AppNotificationController',

  components: {
    NotificationContainer,
    NotificationController,
  },

  NotificationConfig,

  mounted() {
    // This sets up a notifications "sink" for notificaitons emitted from other MFEs
    // See @jumpcloud-ap/notifications for more information
    // At some point, we will move this to the "universal" app (part of Fission Phase 3)
    notificationEventBus.subscribe('notifications:removeNotification', this.removeNotification);
    notificationEventBus.subscribe('notifications:resetNotifications', this.resetNotifications);
    notificationEventBus.subscribe('notifications:triggerErrorNotification', this.triggerErrorNotification);
    notificationEventBus.subscribe('notifications:triggerNotification', this.triggerNotification);
    notificationEventBus.subscribe('notifications:triggerSuccessNotification', this.triggerSuccessNotification);
    notificationEventBus.subscribe('notifications:triggerWarningNotification', this.triggerWarningNotification);
  },

  beforeDestroy() {
    notificationEventBus.unsubscribe('notifications:removeNotification', this.removeNotification);
    notificationEventBus.unsubscribe('notifications:resetNotifications', this.resetNotifications);
    notificationEventBus.unsubscribe('notifications:triggerErrorNotification', this.triggerErrorNotification);
    notificationEventBus.unsubscribe('notifications:triggerNotification', this.triggerNotification);
    notificationEventBus.unsubscribe('notifications:triggerSuccessNotification', this.triggerSuccessNotification);
    notificationEventBus.unsubscribe('notifications:triggerWarningNotification', this.triggerWarningNotification);
  },

  methods: {
    ...mapActions(uiNotificationModuleName, [
      'removeNotification',
      'resetNotifications',
      'triggerErrorNotification',
      'triggerNotification',
      'triggerSuccessNotification',
      'triggerWarningNotification',
    ]),
  },
};
</script>

<style module scoped>
@value higherThanIntercomBannerZIndex: 2147483002;

.notificationContainer.notificationContainer {
  z-index: higherThanIntercomBannerZIndex;
}
</style>
