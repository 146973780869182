import { setupFeatureFlags } from '@jumpcloud/ui-feature-flags';
import Vue from 'vue';

export const {
  FeatureFlagMixin,
  FeatureFlagsModule,
  initializeFlags,
  useFeatureFlags,
} = setupFeatureFlags([
  {
    localName: 'peripheraluiMFE',
    sourceId: '2024-05-22-peripheralui-mfe',
  },
]);

Vue.mixin(FeatureFlagMixin);

declare module 'vue/types/vue' {
  interface Vue {
    $featureFlags: ReturnType<typeof FeatureFlagMixin['computed']['$featureFlags']>;
    $hasFeatureFlag: typeof FeatureFlagMixin['methods']['$hasFeatureFlag'];
  }
}
