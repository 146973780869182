<template>
  <PastDueAccount
    :data-test-id="$testId('pastDueAccount')"
    :eventPayload="eventPayload"
    :isVarOrDistributor="isVarOrDistributor"
  >
    <template v-slot:intro>
      <span
        v-if="isVarOrDistributor || isAWSCustomer"
        :data-test-id="$testId('intro')"
      >
        The account has a significant past due balance and is
        <strong :class="$style.errorText">at risk of service disruption</strong>.
        {{ resolutionInstructions }} to resolve the payment issue. If you've already
        paid, please email
        <ButtonLink
          :class="$style.billingMail"
          :data-test-id="$testId('mailToBillingLink')"
          :text="$options.billingMail"
          @click="openMailToBillingLink"
        />
        a copy of the proof of payment.
      </span>
      <span
        v-else
        :data-test-id="$testId('intro')"
      >
        The account is past due and
        <strong :class="$style.errorText">at risk of service disruption</strong>.
      </span>
    </template>
  </PastDueAccount>
</template>

<script>
import {
  ButtonLink,
} from '@jumpcloud/ui-components';
import { UiEvents } from '@jumpcloud/ui-events-vue';
import { accountsReceivable } from '@/components/ViewportBanner/constants/AccountConstants';
import {
  mapGetters,
} from 'vuex';
import OrganizationsApi from '@/api/OrganizationsApi';
import PastDueAccount from '@/components/ViewportBanner/PastDueAccount.vue';
import PastDueEmailbillingMixin from '@/components/ViewportBanner/PastDueEmailbillingMixin';

const billingMail = 'billing@jumpcloud.com';

export default {
  name: 'PastDueAccountSecondWarningBannerSlot',

  billingMail,

  components: {
    ButtonLink,
    PastDueAccount,
  },

  mixins: [PastDueEmailbillingMixin],

  data() {
    return {
      accountsReceivable: accountsReceivable.NONE,
    };
  },

  computed: {
    ...mapGetters('EntitlementModule', ['isVarOrDistributor']),

    ...mapGetters('CoreUserInfoModule', ['orgId']),

    eventPayload() {
      return {
        description: 'Accounts Receivable second warning in Admin Portal',
        displayLogic: '"accountsReceivable" for org is "Second Warning"',
        section: 'Banners',
      };
    },

    isAWSCustomer() {
      const isAWSCustomer = this.accountsReceivable === accountsReceivable.AWS_SECOND_WARNING;

      return isAWSCustomer;
    },

    resolutionInstructions() {
      let text = 'Please contact your reseller';

      if (this.isAWSCustomer) {
        text = 'Please contact AWS';
      }

      return text;
    },
  },

  async created() {
    const orgDetails = await OrganizationsApi.getOrganizationDetails(this.orgId);
    this.accountsReceivable = orgDetails.accountsReceivable;
  },

  methods: {
    openMailToBillingLink() {
      window.open(this.mailToBillingLink, '_blank');
      this.triggerLinkClicked(this.$options.billingMail, this.mailToBillingLink);
    },

    triggerLinkClicked(text, toUrl) {
      UiEvents.triggerLinkClicked({
        ...this.commonEventAttributes,
        text,
        toUrl,
      });
    },
  },
};
</script>

<style module>
.billingMail.billingMail {
  text-transform: lowercase;
}

.errorText {
  color: var(--jcError900);
}
</style>
