<template>
  <div
    class="app-root"
  >
    <AppNotificationController />

    <InactivityModal />

    <MtpNavigationBar />
    <StatusBanner />

    <ViewportNudge />
    <ViewportBanner />
  </div>
</template>

<script>
import AppNotificationController from '@/components/Notifications/AppNotificationController.vue';
import InactivityModal from '@/components/InactivityModal.vue';
import MtpNavigationBar from '@/components/MtpNavigationBar.vue';
import StatusBanner from '@/components/StatusBanner.vue';
import ViewportBanner from '@/components/ViewportBanner/ViewportBanner.vue';
import ViewportNudge from '@/components/Nudges/ViewportNudge.vue';

export default {
  name: 'PeripheralUI',

  components: {
    AppNotificationController,
    InactivityModal,
    MtpNavigationBar,
    StatusBanner,
    ViewportBanner,
    ViewportNudge,
  },
};
</script>

<style scoped>
/* app-root should occupy 100% of its space */
.app-root {
  height: 100%;
  width: 100%;
}
</style>
