<template>
  <ParagraphBody>
    <b>Advanced Certification</b>
    is now live on JumpCloud University! The exam, study guide, and learning path are available
    <Link
      href="https://university.jumpcloud.com/courses/advanced-certification-exam"
      isExternal
      target="_blank"
      text="here."
    />
  </ParagraphBody>
</template>

<script>
import {
  Link,
  ParagraphBody,
} from '@jumpcloud/ui-components';

export default {
  name: 'AdvancedCertificationBannerSlot',

  components: {
    Link,
    ParagraphBody,
  },
};
</script>
