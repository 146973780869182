import { createJcApiAxiosInstance } from '@jumpcloud-ap/axios-setup';

export class UsersApi {
  constructor() {
    this.axiosInstance = createJcApiAxiosInstance();
  }

  async fetchUsers() {
    const response = await this.axiosInstance.get('/api/users?sort=created');
    return response.data;
  }
}

export default new UsersApi();
